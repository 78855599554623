.web-site-content {
  padding-top: 116px;
}
.web-site-content .top-content {
  position: relative;
  background: url(../assets/images/top-bg.png) no-repeat;
  background-size: 100% 100%;
}
.web-site-content .top-content .phone-top {
  display: none;
}
.web-site-content .top-content .without-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 180px;
}
.web-site-content .top-content .without-content .limits {
  text-align: left;
}
.web-site-content .top-content .without-content .limits .app-name {
  display: flex;
  align-items: center;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #181A34;
}
.web-site-content .top-content .without-content .limits .app-name .mobius {
  color: #A564F1;
  background: rgba(165, 100, 241, 0.1);
  border: 1px dashed #A564F1;
  padding: 5px 10px;
  margin-right: 10px;
}
.web-site-content .top-content .without-content .limits .tade {
  font-size: 56px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #181A34;
  line-height: 74px;
  margin-top: 10px;
  margin-top: 5px;
}
.web-site-content .top-content .without-content .limits p {
  max-width: 560px;
  font-size: 18px;
  font-family: ArialMT;
  color: #7E8297;
  line-height: 27px;
}
.web-site-content .top-content .without-content .limits .rewards-but {
  display: flex;
  align-items: center;
}
.web-site-content .top-content .without-content .limits .rewards-but .link-but {
  width: 250px;
  height: 55px;
  line-height: 55px;
  background: #A564F1;
  border: 1px solid #8962FF;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
  font-family: ArialMT;
  color: #FFFFFF;
  text-decoration: none;
}
.web-site-content .top-content .without-content .limits .rewards-but .link-bcontact {
  font-size: 18px;
  font-family: ArialMT;
  color: #5A208B;
  margin-left: 20px;
}
.web-site-content .top-content .without-content .limits .rewards-but .link-but:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.web-site-content .top-content .without-content .barrier-banner {
  flex: 1;
  position: relative;
}
.web-site-content .top-content .without-content .barrier-banner .banner {
  position: absolute;
  top: -120px;
  left: -100px;
}
.web-site-content .top-content .without-content .barrier-banner .trade-but {
  z-index: 99;
  cursor: pointer;
  position: absolute;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  background: #7D6EF4;
  font-weight: bold;
  color: #fff;
  width: 462px;
  bottom: -117px;
  right: 27px;
  text-decoration: none;
}
.web-site-content .top-content .without-content .barrier-banner .trade-but:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.web-site-content .top-content .assets-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
  padding-top: 260px;
}
.web-site-content .top-content .assets-content .title {
  position: relative;
  text-align: left;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #181A34;
}
.web-site-content .top-content .assets-content .title .cut {
  position: absolute;
  bottom: 0;
  height: 8px;
  width: 117px;
  background: #7D6EF4;
  left: 450px;
}
.web-site-content .top-content .assets-content p {
  margin-top: 50px;
  text-align: left;
  width: 1004px;
  height: 81px;
  font-size: 18px;
  font-family: Arial;
  color: #7E8297;
  line-height: 27px;
}
.web-site-content .top-content .assets-content .list-item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}
.web-site-content .top-content .assets-content .list-item .item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(127, 113, 240, 0.18);
  border-radius: 12px;
  text-align: left;
  height: 315px;
}
.web-site-content .top-content .assets-content .list-item .item .logo img {
  width: 120px;
}
.web-site-content .top-content .assets-content .list-item .item .logo .phone-title {
  display: none;
}
.web-site-content .top-content .assets-content .list-item .item .title {
  font-size: 23px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #111131;
}
.web-site-content .top-content .assets-content .list-item .item .content {
  font-size: 16px;
  font-family: Arial;
  color: #7E8297;
  line-height: 32px;
  margin-top: 30px;
}
.web-site-content .top-content .assets-content .list-item .item:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.web-site-content .top-content .assets-content .list-item .active {
  margin-top: 20px;
}
.web-site-content .top-content .leftBg {
  position: absolute;
  left: 0;
  top: 450px;
  width: 250px;
}
.web-site-content .top-content .rightBg {
  position: absolute;
  right: 0;
  bottom: 250px;
  width: 150px;
}
.web-site-content .uni-content {
  background: url(../assets/images/uni-bg.png) no-repeat;
  background-size: 100% 100%;
}
.web-site-content .uni-content .features-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
  padding-top: 120px;
}
.web-site-content .uni-content .features-content .title {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: left;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #181A34;
}
.web-site-content .uni-content .features-content .title .cut {
  position: absolute;
  bottom: 0;
  height: 8px;
  width: 117px;
  background: #7D6EF4;
  left: 700px;
}
.web-site-content .uni-content .features-content .grid-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 50px;
}
.web-site-content .uni-content .features-content .grid-list .item {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(127, 113, 240, 0.18);
  border-radius: 12px;
  padding: 20px 30px;
  height: 260px;
}
.web-site-content .uni-content .features-content .grid-list .item .header {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #111131;
}
.web-site-content .uni-content .features-content .grid-list .item .header img {
  width: 86px;
  margin-right: 20px;
}
.web-site-content .uni-content .features-content .grid-list .item .content {
  text-align: left;
  font-size: 16px;
  font-family: Arial;
  color: #7E8297;
  line-height: 32px;
  margin-top: 30px;
}
.web-site-content .uni-content .features-content .grid-list .item:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.web-site-content .uni-content .features-content .grid-list .active {
  margin-top: 30px;
}
.web-site-content .uni-content .finance-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
  padding-top: 120px;
}
.web-site-content .uni-content .finance-content .title {
  position: relative;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #181A34;
}
.web-site-content .uni-content .finance-content .title .cut {
  position: absolute;
  bottom: 10pz;
  height: 8px;
  width: 117px;
  background: #7D6EF4;
  left: 720px;
}
.web-site-content .uni-content .finance-content .content {
  padding: 0 150px;
  font-size: 18px;
  font-family: Arial;
  color: #7E8297;
  line-height: 27px;
  margin-top: 30px;
  text-align: left;
}
.web-site-content .uni-content .finance-content .list-tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  border-top: 8px solid #E3D0FA;
  border-bottom: 8px solid #E3D0FA;
  border-right: 8px solid #E3D0FA;
  border-left: none;
  border-radius: 0 100px 100px 0;
}
.web-site-content .uni-content .finance-content .list-tabs .tab {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 30px 30px 60px 30px;
  align-items: center;
  font-size: 28px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #9298A9;
  line-height: 32px;
}
.web-site-content .uni-content .finance-content .list-tabs .tab img {
  width: 40px;
  margin-right: 20px;
}
.web-site-content .uni-content .finance-content .list-tabs .tab:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  color: #A564F1;
}
.web-site-content .uni-content .finance-content .list-tabs .active {
  font-size: 28px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #A564F1;
}
.web-site-content .uni-content .finance-content .list-tabs .top-cut {
  position: absolute;
  top: -8px;
  left: 0;
  height: 8px;
  background: #A564F1;
  width: 220px;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten {
  position: absolute;
  border-radius: 24px;
  width: 1080px;
  top: 100px;
  z-index: 1;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header {
  background: #302E7A;
  padding: 20px;
  border-radius: 24px 24px 0 0;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content {
  text-align: left;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #fff;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title .mint-burn span {
  cursor: pointer;
  color: #9298A9;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title .mint-burn .active {
  color: #A564F1;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title .but {
  cursor: pointer;
  color: #A564F1;
  font-size: 15px;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .tip-content {
  font-size: 16px;
  font-family: Arial;
  line-height: 28px;
  margin-top: 20px;
  width: 940px;
  color: #ACABC9;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .active {
  color: #4C4C51;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .active {
  background: #F7F7FB;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .tab-trade {
  border-radius: 0 0 24px 24px;
  width: 100%;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .tab-trade img {
  width: 100%;
}
.web-site-content .uni-content .finance-content .list-tabs .tab-conten .tab-trade .phone-img {
  display: none;
}
.web-site-content .uni-content .finance-content .list-tabs .phone-tabs {
  display: none;
}
.web-site-content .deploy-content {
  position: relative;
  height: 700px;
  background: #212058;
  margin-top: 320px;
  padding-top: 200px;
}
.web-site-content .deploy-content .place-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
}
.web-site-content .deploy-content .place-content .title {
  position: relative;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #FFFFFF;
  padding: 10px;
}
.web-site-content .deploy-content .place-content .title .cut {
  position: absolute;
  bottom: 0;
  height: 8px;
  width: 117px;
  background: #7D6EF4;
  left: 390px;
}
.web-site-content .deploy-content .place-content .grid-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 80px;
}
.web-site-content .deploy-content .place-content .grid-list .item {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(131, 116, 255, 0.18);
  border-radius: 12px;
  padding: 20px;
}
.web-site-content .deploy-content .place-content .grid-list .item .label {
  font-size: 24px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #111131;
  margin-top: 30px;
}
.web-site-content .deploy-content .place-content .grid-list .item .content {
  margin-top: 30px;
  font-size: 16px;
  font-family: Arial;
  color: #7E8297;
  line-height: 30px;
}
.web-site-content .deploy-content .place-content .grid-list .item img {
  width: 80px;
}
.web-site-content .deploy-content .place-content .grid-list .item:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.web-site-content .deploy-content .place-content .place-bottom-bg {
  position: absolute;
  left: 0;
  bottom: -330px;
  width: 300px;
}
.web-site-content .deploy-content .place-content .airplane {
  position: absolute;
  left: -180px;
  top: -380px;
  width: 900px;
}
.web-site-content .deploy-content .place-right-bg {
  position: absolute;
  right: 0;
  top: 200px;
  width: 150px;
}
.web-site-content .powered-content {
  background: #F8F8FF;
  padding: 60px 0;
}
.web-site-content .powered-content .polygon-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
}
.web-site-content .powered-content .polygon-content .title {
  position: relative;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #181A34;
  padding: 10px;
}
.web-site-content .powered-content .polygon-content .title .cut {
  position: absolute;
  bottom: 0;
  height: 8px;
  width: 117px;
  background: #7D6EF4;
  left: 660px;
}
.web-site-content .powered-content .polygon-content .list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 60px 0;
}
.web-site-content .powered-content .polygon-content .list .item img {
  width: 550px;
}
.web-site-content .powered-content .polygon-content .list .item:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.web-site-content .our-content {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 30px;
}
.web-site-content .our-content .partners-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
}
.web-site-content .our-content .partners-content .title {
  position: relative;
  font-size: 56px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #181A34;
  padding: 10px;
}
.web-site-content .our-content .partners-content .title .cut {
  position: absolute;
  bottom: 0;
  height: 8px;
  width: 117px;
  background: #7D6EF4;
  left: 550px;
}
.web-site-content .our-content .partners-content .our-content .list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 60px;
}
.web-site-content .our-content .partners-content .our-content .list img {
  height: 55px;
}
.web-site-content .our-content .partners-content .our-content .our-list {
  display: none;
}
.web-site-content .bottom-content {
  background: #F8F8FF;
  padding-top: 80px;
  padding-bottom: 40px;
}
.web-site-content .bottom-content .footer-content {
  width: 1200px;
  margin: 0px auto;
  position: relative;
  display: flex;
  align-items: center;
}
.web-site-content .bottom-content .footer-content .left-content {
  text-align: left;
}
.web-site-content .bottom-content .footer-content .left-content .footer-left-top {
  display: flex;
  align-items: center;
}
.web-site-content .bottom-content .footer-content .left-content .footer-left-top a img {
  width: 24px;
  margin-right: 30px;
}
.web-site-content .bottom-content .footer-content .left-content .footer-left-top .mobius-logo {
  height: 38px;
  margin-right: 60px;
}
.web-site-content .bottom-content .footer-content .left-content .footer-left-bottom {
  margin-top: 30px;
  font-size: 14px;
  font-family: DINPro-Regular, DINPro;
  font-weight: 400;
  color: #6A6972;
}
.web-site-content .bottom-content .footer-content .right-content {
  text-align: left;
  margin-left: 100px;
}
.web-site-content .bottom-content .footer-content .right-content .dis {
  font-size: 16px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #37373C;
  margin-bottom: 10px;
}
.web-site-content .bottom-content .footer-content .right-content .footer-right-content {
  font-size: 14px;
  font-family: DINPro-Regular, DINPro;
  font-weight: 400;
  color: #6A6972;
  margin-top: 20px;
}
.web-site-content .phone-footer {
  display: none;
}
@media screen and (max-width: 750px) {
  .web-site-content {
    padding-top: 110px;
  }
  .web-site-content .top-content {
    position: relative;
    background: linear-gradient(181deg, #E8E2FF 0%, rgba(255, 255, 255, 0) 100%);
  }
  .web-site-content .top-content .phone-top {
    width: 100%;
    display: block;
    padding-top: 10px;
  }
  .web-site-content .top-content .without-content {
    width: 100%;
    padding-top: 40px;
  }
  .web-site-content .top-content .without-content .limits {
    flex: 1;
    text-align: center;
  }
  .web-site-content .top-content .without-content .limits .app-name {
    justify-content: center;
    font-size: 32px;
  }
  .web-site-content .top-content .without-content .limits .app-name .mobius {
    color: #A564F1;
    background: rgba(165, 100, 241, 0.1);
    border: 1px dashed #A564F1;
    padding: 5px 10px;
    margin-right: 10px;
  }
  .web-site-content .top-content .without-content .limits .tade {
    font-size: 24px;
    margin-top: -10px;
  }
  .web-site-content .top-content .without-content .limits p {
    font-size: 14px;
    padding: 0 10px;
    margin: 0;
  }
  .web-site-content .top-content .without-content .limits .rewards-but {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .web-site-content .top-content .without-content .limits .rewards-but .link-but {
    width: 200px;
    font-size: 14px;
  }
  .web-site-content .top-content .without-content .limits .rewards-but .link-bcontact {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 0;
  }
  .web-site-content .top-content .without-content .barrier-banner {
    display: none;
  }
  .web-site-content .top-content .assets-content {
    width: 100%;
    padding-top: 60px;
  }
  .web-site-content .top-content .assets-content .title {
    text-align: center;
    font-size: 24px;
  }
  .web-site-content .top-content .assets-content .title .cut {
    position: absolute;
    bottom: -10px;
    height: 8px;
    width: 70px;
    background: #7D6EF4;
    left: 210px;
  }
  .web-site-content .top-content .assets-content p {
    box-sizing: border-box;
    text-align: left;
    margin-top: 50px;
    width: 100%;
    font-size: 14px;
    padding: 0 10px;
    height: 100%;
  }
  .web-site-content .top-content .assets-content .list-item {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 10px;
  }
  .web-site-content .top-content .assets-content .list-item .item {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 0px rgba(127, 113, 240, 0.18);
    border-radius: 12px;
    text-align: left;
    height: 140px;
  }
  .web-site-content .top-content .assets-content .list-item .item .logo {
    display: flex;
    align-items: center;
  }
  .web-site-content .top-content .assets-content .list-item .item .logo img {
    width: 80px;
  }
  .web-site-content .top-content .assets-content .list-item .item .logo .phone-title {
    display: block;
    font-size: 15px;
    font-family: Arial-Mdm, Arial;
    font-weight: normal;
    color: #111131;
  }
  .web-site-content .top-content .assets-content .list-item .item .title {
    display: none;
    text-align: left;
    font-size: 16px;
  }
  .web-site-content .top-content .assets-content .list-item .item .content {
    font-size: 14px;
    line-height: 28px;
    margin-top: 10px;
  }
  .web-site-content .top-content .assets-content .list-item .active {
    margin-top: 0;
  }
  .web-site-content .top-content .leftBg {
    display: none;
  }
  .web-site-content .top-content .rightBg {
    display: none;
  }
  .web-site-content .uni-content .features-content {
    width: 100%;
    padding-top: 100px;
  }
  .web-site-content .uni-content .features-content .title {
    justify-content: center;
    text-align: center;
    font-size: 24px;
  }
  .web-site-content .uni-content .features-content .title .cut {
    position: absolute;
    bottom: -10px;
    width: 70px;
    left: 230px;
  }
  .web-site-content .uni-content .features-content .grid-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 50px;
    padding: 0 10px;
  }
  .web-site-content .uni-content .features-content .grid-list .item {
    padding: 15px 20px;
    height: 170px;
  }
  .web-site-content .uni-content .features-content .grid-list .item .header {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .web-site-content .uni-content .features-content .grid-list .item .header img {
    width: 40px;
    margin-right: 20px;
  }
  .web-site-content .uni-content .features-content .grid-list .item .content {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
  }
  .web-site-content .uni-content .features-content .grid-list .active {
    margin-top: 0;
  }
  .web-site-content .uni-content .finance-content {
    width: 100%;
    padding-top: 80px;
  }
  .web-site-content .uni-content .finance-content .title {
    font-size: 24px;
  }
  .web-site-content .uni-content .finance-content .title .cut {
    position: absolute;
    width: 70px;
    height: 8px;
    left: 150px;
    background: #7D6EF4;
  }
  .web-site-content .uni-content .finance-content .content {
    padding: 10px;
    font-size: 14px;
    margin-top: 30px;
  }
  .web-site-content .uni-content .finance-content .list-tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    border: none;
    border-radius: 0 100px 100px 0;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab {
    display: none;
  }
  .web-site-content .uni-content .finance-content .list-tabs .active {
    font-size: 16px;
    font-family: Arial-Mdm, Arial;
    font-weight: normal;
    color: #A564F1;
  }
  .web-site-content .uni-content .finance-content .list-tabs .top-cut {
    display: none;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten {
    position: absolute;
    border-radius: none;
    width: 100%;
    top: 70px;
    z-index: 1;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header {
    background: #302E7A;
    padding: 20px;
    border-radius: 0;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content {
    text-align: left;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title {
    font-size: 16px;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title .mint-burn span {
    cursor: pointer;
    color: #9298A9;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title .mint-burn .active {
    color: #A564F1;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .title .but {
    cursor: pointer;
    color: #A564F1;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .tip-content {
    font-size: 14px;
    margin-top: 20px;
    line-height: 26px;
    width: 100%;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .header .header-content .active {
    color: #4C4C51;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .active {
    background: #F7F7FB;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .tab-trade {
    border-radius: 0 0 24px 24px;
    width: 100%;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .tab-trade img {
    display: none;
    width: 100%;
  }
  .web-site-content .uni-content .finance-content .list-tabs .tab-conten .tab-trade .phone-img {
    display: block;
  }
  .web-site-content .uni-content .finance-content .list-tabs .phone-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    border-bottom: 5px solid #E3D0FA;
  }
  .web-site-content .uni-content .finance-content .list-tabs .phone-tabs .item-tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 12px;
    font-weight: bold;
  }
  .web-site-content .uni-content .finance-content .list-tabs .phone-tabs .item-tab img {
    width: 20px;
    margin-right: 3px;
  }
  .web-site-content .uni-content .finance-content .list-tabs .phone-tabs .active {
    border-bottom: 5px solid #A564F1;
  }
  .web-site-content .deploy-content {
    position: relative;
    background: #212058;
    height: 100%;
    margin-top: 450px;
    padding-top: 200px;
  }
  .web-site-content .deploy-content .place-content {
    width: 100%;
    margin: 0px auto;
    position: relative;
    background: #212058;
    padding-bottom: 20px;
    margin-top: 60px;
  }
  .web-site-content .deploy-content .place-content .title {
    position: relative;
    font-size: 24px;
    padding: 10px;
  }
  .web-site-content .deploy-content .place-content .title .cut {
    position: absolute;
    bottom: 0;
    height: 8px;
    width: 100px;
    background: #7D6EF4;
    left: 140px;
  }
  .web-site-content .deploy-content .place-content .grid-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 40px;
    padding: 0 10px;
  }
  .web-site-content .deploy-content .place-content .grid-list .item {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 0px rgba(131, 116, 255, 0.18);
    border-radius: 12px;
    padding: 20px;
  }
  .web-site-content .deploy-content .place-content .grid-list .item .label {
    font-size: 16px;
    margin-top: 10px;
  }
  .web-site-content .deploy-content .place-content .grid-list .item .content {
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
  .web-site-content .deploy-content .place-content .grid-list .item img {
    width: 60px;
  }
  .web-site-content .deploy-content .place-content .place-bottom-bg {
    display: none;
  }
  .web-site-content .deploy-content .place-content .airplane {
    display: none;
  }
  .web-site-content .deploy-content .place-right-bg {
    position: absolute;
    right: 0;
    top: 200px;
    width: 150px;
  }
  .web-site-content .powered-content {
    background: #F8F8FF;
    padding: 0;
    padding-top: 30px;
  }
  .web-site-content .powered-content .polygon-content {
    width: 100%;
  }
  .web-site-content .powered-content .polygon-content .title {
    font-size: 24px;
    padding: 10px;
  }
  .web-site-content .powered-content .polygon-content .title .cut {
    position: absolute;
    bottom: 0;
    height: 8px;
    width: 70px;
    background: #7D6EF4;
    left: 210px;
  }
  .web-site-content .powered-content .polygon-content .list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    margin: 30px 0;
  }
  .web-site-content .powered-content .polygon-content .list .item img {
    width: 100%;
  }
  .web-site-content .our-content {
    background: #fff;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .web-site-content .our-content .partners-content {
    width: 100%;
  }
  .web-site-content .our-content .partners-content .title {
    font-size: 24px;
    padding: 10px;
  }
  .web-site-content .our-content .partners-content .title .cut {
    width: 70px;
    left: 170px;
  }
  .web-site-content .our-content .partners-content .our-content {
    margin-top: 20px;
  }
  .web-site-content .our-content .partners-content .our-content .list {
    display: none;
  }
  .web-site-content .our-content .partners-content .our-content .our-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    gap: 20px;
  }
  .web-site-content .our-content .partners-content .our-content .our-list img {
    flex: 1;
    width: 90px;
  }
  .web-site-content .bottom-content {
    display: none;
  }
  .web-site-content .phone-footer {
    display: block;
    padding: 20px 10px;
    background: #F4F5F9;
  }
  .web-site-content .phone-footer .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #C7C7CD;
  }
  .web-site-content .phone-footer .footer-content .left-content .dis {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .web-site-content .phone-footer .footer-content .left-content .mobius-logo {
    height: 25px;
  }
  .web-site-content .phone-footer .footer-content .right-content {
    margin-left: 10px;
    font-size: 14px;
    font-family: DINPro-Regular, DINPro;
    font-weight: 400;
    color: #6A6972;
    text-align: left;
    line-height: 20px;
  }
  .web-site-content .phone-footer .Copyright {
    font-size: 14px;
    font-family: DINPro-Regular, DINPro;
    font-weight: 400;
    color: #6A6972;
    margin-top: 20px;
  }
}
