





































































































































































































































































































































































































































































.web-site-content {
    padding-top: 116px;
    .top-content {
        position: relative;
        background: url(../assets/images/top-bg.png) no-repeat;
        background-size:100% 100%;
        .phone-top {
            display: none;
        }
        .without-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            display: flex;
            justify-content: space-between;
            padding-top: 180px;
            .limits {
                text-align: left;
                .app-name {
                    display: flex;
                    align-items: center;
                    font-size: 56px;
                    font-family: Arial-BoldMT, Arial;
                    font-weight: normal;
                    color: #181A34;
                    .mobius {
                        color: #A564F1;
                        background: rgba(165, 100, 241, 0.1);
                        border: 1px dashed #A564F1;
                        padding: 5px 10px;
                        margin-right: 10px;
                    }
                }
                .tade {
                    font-size: 56px;
                    font-family: Arial-Mdm, Arial;
                    font-weight: normal;
                    color: #181A34;
                    line-height: 74px;
                    margin-top: 10px;
                    margin-top: 5px;
                }
                p {
                    max-width: 560px;
                    font-size: 18px;
                    font-family: ArialMT;
                    color: #7E8297;
                    line-height: 27px;
                }
                .rewards-but {
                    display: flex;
                    align-items: center;
                    .link-but {
                        width: 250px;
                        height: 55px;
                        line-height: 55px;
                        background: #A564F1;
                        border: 1px solid #8962FF;
                        border-radius: 30px;
                        text-align: center;
                        font-size: 18px;
                        font-family: ArialMT;
                        color: #FFFFFF;
                        text-decoration: none;
                    }
                    .link-bcontact {
                        font-size: 18px;
                        font-family: ArialMT;
                        color: #5A208B;
                        margin-left: 20px;
                    }
                    .link-but:hover {
                        cursor: pointer;
                        -webkit-transform: scale(1.05);
                        -ms-transform: scale(1.05);
                        transform: scale(1.05);
                    }
                }
            }
            .barrier-banner {
                flex: 1;
                position: relative;
                .banner {
                    position: absolute;
                    top: -120px;
                    left: -100px;
                }
                .trade-but {
                    z-index: 99;
                    cursor: pointer;
                    position: absolute;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 25px;
                    background: #7D6EF4;
                    font-weight: bold;
                    color: #fff;
                    width: 462px;
                    bottom: -117px;
                    right: 27px;
                    text-decoration: none;
                }
                .trade-but:hover {
                    cursor: pointer;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                }
            }
        }

        .assets-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            padding-top: 260px;
            .title {
                position: relative;
                text-align: left;
                font-size: 56px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #181A34;
                .cut {
                    position: absolute;
                    bottom: 0;
                    height: 8px;
                    width: 117px;
                    background:#7D6EF4;
                    left: 450px;
                }
            }
            p {
                margin-top: 50px;
                text-align: left;
                width: 1004px;
                height: 81px;
                font-size: 18px;
                font-family: Arial;
                color: #7E8297;
                line-height: 27px;
            }
            .list-item {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                justify-content: space-between;
                margin-top: 40px;
                .item {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(127, 113, 240, 0.18);
                    border-radius: 12px;
                    text-align: left;
                    height: 315px;
                    .logo {
                        img {
                            width: 120px;
                        }
                        .phone-title {
                            display: none;
                        }
                    }
                    .title {
                        font-size: 23px;
                        font-family: Arial-Mdm, Arial;
                        font-weight: normal;
                        color: #111131;
                    }
                    .content {
                        font-size: 16px;
                        font-family: Arial;
                        color: #7E8297;
                        line-height: 32px;
                        margin-top: 30px;
                    }
                }

                .item:hover {
                    cursor: pointer;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                }
                .active {
                    margin-top: 20px;
                }
            }
        }

        .leftBg {
            position: absolute;
            left: 0;
            top: 450px;
            width: 250px;
        }
        .rightBg {
            position: absolute;
            right: 0;
            bottom: 250px;
            width: 150px;
        }
    }

    .uni-content {
        background: url(../assets/images/uni-bg.png) no-repeat;
        background-size:100% 100%;
        .features-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            padding-top: 120px;
            .title {
                display: flex;
                justify-content: center;
                position: relative;
                text-align: left;
                font-size: 56px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #181A34;
                .cut {
                    position: absolute;
                    bottom: 0;
                    height: 8px;
                    width: 117px;
                    background:#7D6EF4;
                    left: 700px;
                }
            }

            .grid-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                margin-top: 50px;
                .item {
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(127, 113, 240, 0.18);
                    border-radius: 12px;
                    padding: 20px 30px;
                    height: 260px;
                    .header {
                        display: flex;
                        align-items: center;
                        font-size: 24px;
                        font-family: Arial-Mdm, Arial;
                        font-weight: normal;
                        color: #111131;
                        img {
                            width: 86px;
                            margin-right: 20px;
                        }
                    }
                    .content {
                        text-align: left;
                        font-size: 16px;
                        font-family: Arial;
                        color: #7E8297;
                        line-height: 32px;
                        margin-top: 30px;
                    }
                }
                .item:hover {
                    cursor: pointer;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                }
                .active {
                    margin-top: 30px;
                }
            }
        }

        .finance-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            padding-top: 120px;
            .title {
                position: relative;
                font-size: 56px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #181A34;
                .cut {
                    position: absolute;
                    bottom: 10pz;
                    height: 8px;
                    width: 117px;
                    background:#7D6EF4;
                    left: 720px;
                }
            }
            .content {
                padding: 0 150px;
                font-size: 18px;
                font-family: Arial;
                color: #7E8297;
                line-height: 27px;
                margin-top: 30px;
                text-align: left;
            }
            .list-tabs {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 30px;
                border-top: 8px solid #E3D0FA;
                border-bottom: 8px solid #E3D0FA;
                border-right: 8px solid #E3D0FA;
                border-left: none;
                border-radius: 0 100px 100px 0;
                .tab {
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    padding: 30px 30px 60px 30px;
                    align-items: center;
                    font-size: 28px;
                    font-family: Arial-Mdm, Arial;
                    font-weight: normal;
                    color: #9298A9;
                    line-height: 32px;
                    img {
                        width: 40px;
                        margin-right: 20px;
                    }
                }

                .tab:hover {
                    cursor: pointer;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                    color: #A564F1;
                }
                .active {
                    font-size: 28px;
                    font-family: Arial-Mdm, Arial;
                    font-weight: normal;
                    color: #A564F1;
                }
                .top-cut {
                    position: absolute;
                    top: -8px;
                    left: 0;
                    height: 8px;
                    background: #A564F1;
                    width: 220px;
                }

                .tab-conten {
                    position: absolute;
                    border-radius: 24px;
                    width: 1080px;
                    top: 100px;
                    z-index: 1;
                    .header {
                        background: #302E7A;
                        padding: 20px;
                        border-radius: 24px 24px 0 0;
                        .header-content {
                            text-align: left;

                            .title {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 32px;
                                font-family: Arial-Mdm, Arial;
                                font-weight: normal;
                                color: #fff;
                                .mint-burn {
                                    span {
                                        cursor: pointer;
                                        color: #9298A9;
                                    }
                                    .active {
                                       color: #A564F1;
                                    }
                                }
                                .but {
                                    cursor: pointer;
                                    color: #A564F1;
                                    font-size: 15px;
                                }
                            }
                            .tip-content {
                                font-size: 16px;
                                font-family: Arial;
                                line-height: 28px;
                                margin-top: 20px;
                                width: 940px;
                                color: #ACABC9;
                            }
                            .active {
                                color: #4C4C51;
                            }
                        }

                    }
                    .active {
                        background: #F7F7FB;
                    }
                    .tab-trade {
                        border-radius: 0 0 24px 24px;
                        width: 100%;
                        img {
                            width: 100%;
                        }
                        .phone-img {
                            display: none;
                        }
                    }
                }
                .phone-tabs {
                    display: none;
                }
            }

        }
    }

    .deploy-content {
        position: relative;
        height: 700px;
        background: #212058;
        margin-top: 320px;
        padding-top: 200px;
        .place-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            .title {
                position: relative;
                font-size: 56px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #FFFFFF;
                padding: 10px;
                .cut {
                    position: absolute;
                    bottom: 0;
                    height: 8px;
                    width: 117px;
                    background:#7D6EF4;
                    left: 390px;
                }
            }
            .grid-list {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 20px;
                margin-top: 80px;
                .item {
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(131, 116, 255, 0.18);
                    border-radius: 12px;
                    padding: 20px;
                    .label {
                        font-size: 24px;
                        font-family: Arial-Mdm, Arial;
                        font-weight: normal;
                        color: #111131;
                        margin-top: 30px;
                    }
                    .content {
                        margin-top: 30px;
                        font-size: 16px;
                        font-family: Arial;
                        color: #7E8297;
                        line-height: 30px;
                    }
                    img {
                        width: 80px;
                    }
                }
                .item:hover {
                    cursor: pointer;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                }
            }
            .place-bottom-bg {
                position: absolute;
                left: 0;
                bottom: -330px;
                width: 300px;
            }
            .airplane {
                position: absolute;
                left: -180px;
                top: -380px;
                width: 900px;
            }
        }

        .place-right-bg {
            position: absolute;
            right: 0;
            top: 200px;
            width: 150px;
        }
    }

    .powered-content {
        background: #F8F8FF;
        padding: 60px 0;
        .polygon-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            .title {
                position: relative;
                font-size: 56px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #181A34;
                padding: 10px;
                .cut {
                    position: absolute;
                    bottom: 0;
                    height: 8px;
                    width: 117px;
                    background:#7D6EF4;
                    left: 660px;
                }
            }
            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                margin: 60px 0;
                .item {
                    img {
                        width: 550px;
                    }
                }
                .item:hover {
                    cursor: pointer;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                }
            }
        }
    }

    .our-content {
        background: #fff;
        padding-top: 60px;
        padding-bottom: 30px;
        .partners-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            .title {
                position: relative;
                font-size: 56px;
                font-family: Arial-BoldMT, Arial;
                font-weight: normal;
                color: #181A34;
                padding: 10px;
                .cut {
                    position: absolute;
                    bottom: 0;
                    height: 8px;
                    width: 117px;
                    background:#7D6EF4;
                    left: 550px;
                }
            }
            .our-content {
                .list {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    margin-bottom: 60px;
                    img {
                       height: 55px;
                    }
                }
                .our-list {
                    display: none;
                }
            }
        }
    }

    .bottom-content {
        background: #F8F8FF;
        padding-top: 80px;
        padding-bottom: 40px;
        .footer-content {
            width: 1200px;
            margin: 0px auto;
            position: relative;
            display: flex;
            align-items: center;
            .left-content {
                text-align: left;
                .footer-left-top {
                    display: flex;
                    align-items: center;
                    a {
                        img {
                            width: 24px;
                            margin-right: 30px;
                        }
                    }
                    .mobius-logo {
                        height: 38px;
                        margin-right: 60px;
                    }
                }
                .footer-left-bottom {
                    margin-top: 30px;
                    font-size: 14px;
                    font-family: DINPro-Regular, DINPro;
                    font-weight: 400;
                    color: #6A6972;
                }
            }
            .right-content {
                text-align: left;
                margin-left: 100px;
                .dis {
                    font-size: 16px;
                    font-family: DINPro-Medium, DINPro;
                    font-weight: 500;
                    color: #37373C;
                    margin-bottom: 10px;
                }
                .footer-right-content {
                    font-size: 14px;
                    font-family: DINPro-Regular, DINPro;
                    font-weight: 400;
                    color: #6A6972;
                    margin-top: 20px;
                }
            }
        }
    }

    .phone-footer {
        display: none;
    }
}

@media screen and (max-width: 750px) {
    .web-site-content {
        padding-top: 110px;
        .top-content {
            position: relative;
            background: linear-gradient(181deg, #E8E2FF 0%, rgba(255, 255, 255, 0) 100%);
            .phone-top {
                width: 100%;
                display: block;
                padding-top: 10px;
            }
            .without-content {
                width: 100%;
                padding-top: 40px;
                .limits {
                    flex: 1;
                    text-align: center;
                    .app-name {
                        justify-content: center;
                        font-size: 32px;
                        .mobius {
                            color: #A564F1;
                            background: rgba(165, 100, 241, 0.1);
                            border: 1px dashed #A564F1;
                            padding: 5px 10px;
                            margin-right: 10px;
                        }
                    }
                    .tade {
                        font-size: 24px;
                        margin-top: -10px;
                    }
                    p {
                        font-size: 14px;
                        padding: 0 10px;
                        margin: 0;
                    }
                    .rewards-but {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        .link-but {
                            width: 200px;
                            font-size: 14px;
                        }
                        .link-bcontact {
                            font-size: 14px;
                            margin-top: 10px;
                            margin-left: 0;
                        }
                    }
                }
                .barrier-banner {
                    display: none;
                }
            }

            .assets-content {
                width: 100%;
                padding-top: 60px;
                .title {
                    text-align: center;
                    font-size: 24px;
                    .cut {
                        position: absolute;
                        bottom: -10px;
                        height: 8px;
                        width: 70px;
                        background:#7D6EF4;
                        left: 210px;

                    }
                }
                p {
                    box-sizing: border-box;
                    text-align: left;
                    margin-top: 50px;
                    width: 100%;
                    font-size: 14px;
                    padding: 0 10px;
                    height: 100%;
                }
                .list-item {
                    grid-template-columns: repeat(1, 1fr);
                    padding: 0 10px;
                    .item {
                        display: flex;
                        flex-direction: column;
                        padding: 10px 20px;
                        background: #FFFFFF;
                        box-shadow: 0px 10px 20px 0px rgba(127, 113, 240, 0.18);
                        border-radius: 12px;
                        text-align: left;
                        height: 140px;
                        .logo {
                            display: flex;
                            align-items: center;
                            img {
                                width: 80px;
                            }
                            .phone-title {
                                display: block;
                                font-size: 15px;
                                font-family: Arial-Mdm, Arial;
                                font-weight: normal;
                                color: #111131;
                            }
                        }
                        .title {
                            display: none;
                            text-align: left;
                            font-size: 16px;
                        }
                        .content {
                            font-size: 14px;
                            line-height: 28px;
                            margin-top: 10px;
                        }
                    }
                    .active {
                        margin-top: 0;
                    }
                }
            }

            .leftBg {
                display: none;
            }
            .rightBg {
                display: none;
            }
        }

        .uni-content {
            .features-content {
                width: 100%;
                padding-top: 100px;
                .title {
                    justify-content: center;
                    text-align: center;
                    font-size: 24px;
                    .cut {
                        position: absolute;
                        bottom: -10px;
                        width: 70px;
                        left: 230px;
                    }
                }

                .grid-list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                    margin-top: 50px;
                    padding: 0 10px;
                    .item {
                        padding: 15px 20px;
                        height: 170px;
                        .header {
                            display: flex;
                            align-items: center;
                            font-size: 15px;
                            img {
                                width: 40px;
                                margin-right: 20px;
                            }
                        }
                        .content {
                            text-align: left;
                            font-size: 14px;
                            line-height: 24px;
                            margin-top: 10px;
                        }
                    }
                    .active {
                        margin-top: 0;
                    }
                }
            }

            .finance-content {
                width: 100%;
                padding-top: 80px;
                .title {
                    font-size: 24px;
                    .cut {
                        position: absolute;
                        width: 70px;
                        height: 8px;
                        left: 150px;
                        background:#7D6EF4;
                    }
                }
                .content {
                    padding: 10px;
                    font-size: 14px;
                    margin-top: 30px;
                }
                .list-tabs {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;
                    border: none;
                    border-radius: 0 100px 100px 0;
                    .tab {
                        display: none;
                    }
                    .active {
                        font-size: 16px;
                        font-family: Arial-Mdm, Arial;
                        font-weight: normal;
                        color: #A564F1;
                    }
                    .top-cut {
                        display: none;
                    }

                    .tab-conten {
                        position: absolute;
                        border-radius: none;
                        width: 100%;
                        top: 70px;
                        z-index: 1;
                        .header {
                            background: #302E7A;
                            padding: 20px;
                            border-radius: 0;
                            .header-content {
                                text-align: left;

                                .title {
                                    font-size: 16px;
                                    .mint-burn {
                                        span {
                                            cursor: pointer;
                                            color: #9298A9;
                                        }
                                        .active {
                                        color: #A564F1;
                                        }
                                    }
                                    .but {
                                        cursor: pointer;
                                        // color: #7D6EF4;
                                        color: #A564F1;
                                    }
                                }
                                .tip-content {
                                    font-size: 14px;
                                    margin-top: 20px;
                                    line-height: 26px;
                                    width: 100%;
                                }
                                .active {
                                    color: #4C4C51;
                                }
                            }

                        }
                        .active {
                            background: #F7F7FB;
                        }
                        .tab-trade {
                            border-radius: 0 0 24px 24px;
                            width: 100%;
                            img {
                                display: none;
                                width: 100%;
                            }
                            .phone-img {
                                display: block;
                            }
                        }
                    }
                    .phone-tabs {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        width: 100%;
                        height: 50px;
                        border-bottom: 5px solid #E3D0FA;
                        .item-tab {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 50px;
                            font-size: 12px;
                            font-weight: bold;
                            img {
                                width: 20px;
                                margin-right: 3px;
                            }
                        }

                        .active {
                            border-bottom: 5px solid #A564F1;
                        }
                    }
                }
            }
        }

        .deploy-content {
            position: relative;
            background: #212058;
            height: 100%;
            margin-top: 450px;
            padding-top: 200px;
            .place-content {
                width: 100%;
                margin: 0px auto;
                position: relative;
                background: #212058;
                padding-bottom: 20px;
                margin-top: 60px;
                .title {
                    position: relative;
                    font-size: 24px;
                    padding: 10px;
                    .cut {
                        position: absolute;
                        bottom: 0;
                        height: 8px;
                        width: 100px;
                        background:#7D6EF4;
                        left: 140px;
                    }
                }
                .grid-list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                    margin-top: 40px;
                    padding: 0 10px;
                    .item {
                        background: #FFFFFF;
                        box-shadow: 0px 10px 20px 0px rgba(131, 116, 255, 0.18);
                        border-radius: 12px;
                        padding: 20px;
                        .label {
                            font-size: 16px;
                            margin-top: 10px;
                        }
                        .content {
                            margin-top: 10px;
                            font-size: 14px;
                            line-height: 24px;
                            text-align: left;
                        }
                        img {
                            width: 60px;
                        }
                    }
                }
                .place-bottom-bg {
                    display: none;
                }
                .airplane {
                    display: none;
                }
            }

            .place-right-bg {
                position: absolute;
                right: 0;
                top: 200px;
                width: 150px;
            }
        }

        .powered-content {
            background: #F8F8FF;
            padding: 0;
            padding-top: 30px;
            .polygon-content {
                width: 100%;
                .title {
                    font-size: 24px;
                    padding: 10px;
                    .cut {
                        position: absolute;
                        bottom: 0;
                        height: 8px;
                        width: 70px;
                        background:#7D6EF4;
                        left: 210px;
                    }
                }
                .list {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 0;
                    margin: 30px 0;
                    .item {
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .our-content {
            background: #fff;
            padding-top: 20px;
            padding-bottom: 30px;
            .partners-content {
                width: 100%;
                .title {
                    font-size: 24px;
                    padding: 10px;
                    .cut {
                        width: 70px;
                        left: 170px;
                    }
                }
                .our-content {
                    margin-top: 20px;
                    .list {
                        display: none;
                    }
                    .our-list {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        align-items: center;
                        justify-items: center;
                        gap: 20px;
                        img {
                            flex: 1;
                            width: 90px;
                        }
                    }
                }
            }
        }

        .bottom-content {
            display: none;
        }

        .phone-footer {
            display: block;
            padding: 20px 10px;
            background: #F4F5F9;
            .footer-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;
                border-bottom: 1px solid #C7C7CD;
                .left-content {
                    .dis {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 15px;
                    }
                    .mobius-logo {
                        height: 25px;
                    }
                }
                .right-content {
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: DINPro-Regular, DINPro;
                    font-weight: 400;
                    color: #6A6972;
                    text-align: left;
                    line-height: 20px;
                }
            }
            .Copyright {
                font-size: 14px;
                font-family: DINPro-Regular, DINPro;
                font-weight: 400;
                color: #6A6972;
                margin-top: 20px;
            }
        }
    }
}
