.app-header {
  position: fixed;
  width: 100%;
  background: linear-gradient(to right, #E6E1FF, #fff);
  z-index: 9999;
}
.app-header .tip-content {
  padding: 10px 0;
  background: #481DA0;
}
.app-header .tip-content .content-text {
  color: #fff;
  line-height: 20px;
  font-family: Helvetica;
  font-size: 14px;
}
.app-header .tip-content .content-text a {
  color: #fff;
}
.app-header .tip-content .content-text .contract-address {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-header .box {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 1176px;
  padding: 4px 0;
  margin: 0px auto;
}
.app-header .box .left-header {
  display: flex;
  align-items: center;
}
.app-header .box .left-header .logo {
  cursor: pointer;
  width: 128px;
  height: auto;
  margin-right: 20px;
}
.app-header .box .left-header .menu-list {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 10px;
}
.app-header .box .left-header .menu-list .menuSelect {
  color: #481DA0;
}
.app-header .box .left-header .menu-list > span {
  font-size: 16px;
  line-height: 2;
  height: 100%;
  font-family: Helvetica;
  color: #37373C;
  cursor: pointer;
}
.app-header .box .left-header .menu-list > a {
  font-size: 16px;
  line-height: 3;
  height: 100%;
  font-family: Helvetica;
  color: #37373C;
  cursor: pointer;
  text-decoration: none;
  margin-left: 45px;
}
.app-header .box .left-header .menu-list > span + span {
  margin-left: 45px;
}
.app-header .box .left-header .menu-list > span.docs {
  position: relative;
  border-radius: 18px;
  border: 1px solid #FFFFFF;
}
.app-header .box .left-header .menu-list > span.docs span {
  position: relative;
  padding: 0 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.app-header .box .left-header .menu-list > span.docs div {
  display: none;
  position: absolute;
  right: 0px;
  top: 33px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 240px;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.app-header .box .left-header .menu-list > span.docs:hover div {
  display: block;
}
.app-header .box .left-header .menu-list > span.docs:hover .popup-wrap .up-box {
  display: block;
}
.app-header .box .left-header .menu-list > span.docs div a {
  font-size: 16px;
  line-height: 14px;
  text-decoration: none;
  color: #37373C;
  display: block;
  padding: 10px 0;
  font-family: Helvetica;
}
.app-header .box .left-header .menu-list > span.docs span::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  width: 7px;
  height: 5px;
}
.app-header .box .left-header .menu-list > span.docs div a:hover {
  color: #ffffff;
  color: #7D6EF4;
}
.app-header .box .left-header .menu-list > span.docs div .popup-con {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #666;
  line-height: 24px;
  padding: 9px 20px;
  display: block;
  top: 0;
}
.app-header .box .left-header .menu-list > span.docs div .popup-con:before {
  width: 10px;
  height: 10px;
  background: #ccc;
  content: '';
  position: absolute;
  top: -5px;
  right: 15px;
  margin-left: -5px;
  transform: rotate(45deg);
  box-shadow: 0 1px 2px 2px #ccc;
}
.app-header .box .left-header .menu-list > span.docs div .popup-con:after {
  width: 100%;
  height: 10px;
  background: #DFDFF2;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  content: '';
  border-radius: 6px;
  display: block;
}
.app-header .box .right-header {
  display: flex;
  align-items: center;
}
.app-header .box .right-header a {
  display: flex;
  align-items: center;
}
.app-header .box .right-header img {
  cursor: pointer;
  margin-right: 30px;
}
.app-header .box .right-header .medium:hover {
  content: url('../assets/imgs/medium-circle_selected.png');
}
.app-header .box .right-header .twitter-logo:hover {
  content: url('../assets/imgs/twitter-sel.png');
}
.app-header .box .right-header .in-logo:hover {
  content: url('../assets/imgs/in-sel.png');
}
.app-header .box .right-header .tele-logo:hover {
  content: url('../assets/imgs/tele-sel.png');
}
.app-header .box .right-header .app-link {
  display: inline-block;
  align-content: center;
  text-align: center;
  width: 80px;
  padding: 5px;
  background: rgba(165, 100, 241, 0.2);
  border: 1px solid #B05BF9;
  font-size: 16px;
  font-family: Arial-Mdm, Arial;
  font-weight: normal;
  color: #A564F1;
  border-radius: 10px;
  margin-right: 20px;
  text-decoration: none;
}
.app-header .box .bcaJjD {
  display: none;
}
@media screen and (max-width: 750px) {
  .app-header {
    width: 7.5rem;
    background: #E8E2FF;
  }
  .app-header .tip-content {
    padding: 0;
  }
  .app-header .tip-content .content-text {
    font-size: 9px;
    line-height: 14px;
    -webkit-transform: scale(0.8);
  }
  .app-header .tip-content .content-text a {
    color: #fff;
  }
  .app-header .tip-content .content-text .contract-address {
    display: flex;
    flex-direction: column;
  }
  .app-header .phone-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 15px;
  }
  .app-header .phone-header .logo-list {
    display: none;
    align-items: center;
    flex: 3;
    justify-content: flex-end;
  }
  .app-header .phone-header .logo-list a {
    display: flex;
    align-items: center;
  }
  .app-header .phone-header .logo-list img {
    text-align: center;
    margin-left: 20px;
    margin-right: 0;
  }
  .app-header .phone-header .logo-list .medium:hover {
    content: url('../assets/imgs/medium-circle_selected.png');
  }
  .app-header .phone-header .logo-list .twitter-logo:hover {
    content: url('../assets/imgs/twitter-sel.png');
  }
  .app-header .phone-header .logo-list .in-logo:hover {
    content: url('../assets/imgs/in-sel.png');
  }
  .app-header .phone-header .logo-list .tele-logo:hover {
    content: url('../assets/imgs/tele-sel.png');
  }
  .app-header .phone-header .logo {
    display: flex;
    flex: 6;
    align-items: center;
    width: 140px;
  }
  .app-header .phone-header .bcaJjD {
    display: block;
    display: flex;
    align-items: center;
  }
  .app-header .phone-header .bcaJjD div {
    display: flex;
    flex-direction: column;
    width: 0.46rem;
    height: 0.37rem;
    padding: 0.05rem 0.1rem;
  }
  .app-header .phone-header .bcaJjD div .menua {
    background: #494852;
  }
  .app-header .phone-header .bcaJjD div .menub {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA4CAYAAABNGP5yAAAAi0lEQVRoQ+3ZgQ3AMAgDQdh/aKLM8dcNavlxMHt3N+FvCcABEDADwjNwDMF8CpTt//99CRBXgAPiBjADIACBuAIQiBtACkAgj0B+HSaAVlgrrBUuJ4FWOJ8CZftrhdXi7gLWYeuwFIgrAIG4AaQABCAQVwACcQNIAQjkKzECuAy5DLkMld8CLkP1FHgk3l/ZaNp6QQAAAABJRU5ErkJggg==);
  }
  .app-header .phone-header .bcaJjD div span {
    width: 0.36rem;
    height: 0.1rem;
    margin: 2px 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA4CAYAAABNGP5yAAAAi0lEQVRoQ+3ZgQ3AMAgDQdh/aKLM8dcNavlxMHt3N+FvCcABEDADwjNwDMF8CpTt//99CRBXgAPiBjADIACBuAIQiBtACkAgj0B+HSaAVlgrrBUuJ4FWOJ8CZftrhdXi7gLWYeuwFIgrAIG4AaQABCAQVwACcQNIAQjkKzECuAy5DLkMld8CLkP1FHgk3l/ZaNp6QQAAAABJRU5ErkJggg==);
  }
  .app-header .phone-header .left-header .menu-list {
    display: flex;
    position: absolute;
    left: 0px;
    top: 110px;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 2.6rem;
    padding: 0.3rem 0.18rem;
    background-color: #303030;
    border-radius: 0.1rem;
  }
  .app-header .phone-header .left-header .menu-list > span {
    width: 100%;
    padding: 0.2rem 0px;
    font-size: 0.24rem;
    line-height: 1;
    height: 100%;
    color: #ffffff;
  }
  .app-header .phone-header .left-header .menu-list > span + span {
    margin-left: 0px;
  }
  .app-header .phone-header .left-header .menu-list > a {
    margin-left: 0px;
    width: 100%;
    text-decoration: none;
    font-size: 0.24rem;
    color: #fff;
  }
  .app-header .phone-header .left-header .menu-list > span {
    width: 100%;
    padding: 0.2rem 0px;
    font-size: 0.24rem;
    line-height: 1;
    height: 100%;
    color: #ffffff;
  }
  .app-header .phone-header .left-header .menu-list > span.docs {
    position: relative;
  }
  .app-header .phone-header .left-header .menu-list > span.docs span {
    position: relative;
    padding: 0px;
    display: block;
    border: 0px;
    border-radius: 0px;
  }
  .app-header .phone-header .left-header .menu-list > span.docs div {
    display: flex;
    position: relative;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: -0.2rem;
    border-radius: 0.06rem;
    background-color: rgba(23, 23, 23, 0.5);
  }
  .app-header .phone-header .left-header .menu-list > span.docs span::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 5px;
    width: 7px;
    height: 5px;
  }
  .app-header .phone-header .left-header .menu-list > span.docs div a {
    width: 100%;
    padding: 0.12rem 0px;
    font-size: 0.24rem;
    line-height: 1;
    text-decoration: none;
    color: #ffffff;
  }
  .app-header .phone-header .left-header .menu-list > span.docs div .popup-con {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    font-size: 13px;
    color: #666;
    line-height: 24px;
    padding: 0;
  }
  .app-header .phone-header .left-header .menu-list > span.docs div .popup-con:before {
    width: 0;
    height: 0;
    background: rgba(23, 23, 23, 0.5);
    content: '';
    position: absolute;
    top: -5px;
    right: 15px;
    margin-left: -5px;
    transform: rotate(45deg);
    box-shadow: none;
  }
  .app-header .phone-header .left-header .menu-list > span.docs div .popup-con:after {
    width: 0;
    height: 0;
    background: rgba(23, 23, 23, 0.5);
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    content: none;
    border-radius: none;
    display: block;
  }
  .app-header .phone-header .to-app {
    height: 40px;
    line-height: 40px;
    background: rgba(165, 100, 241, 0.2);
    border: 1px solid #B05BF9;
    font-size: 16px;
    font-family: Arial-Mdm, Arial;
    font-weight: normal;
    color: #A564F1;
    width: 70px;
    border-radius: 25px;
    text-decoration: none;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .app-header {
    position: fixed;
    width: 100%;
    background: #DFDFF2;
    z-index: 9999;
  }
  .app-header .tip-content {
    padding: 5px 0;
  }
  .app-header .tip-content .content-text {
    font-size: 12px;
    line-height: 14px;
  }
  .app-header .tip-content .content-text a {
    color: #fff;
  }
  .app-header .tip-content .content-text .contract-address {
    display: flex;
    flex-direction: column;
  }
  .app-header .box {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 4px 0;
    margin: 0px auto;
  }
  .app-header .box .left-header {
    display: flex;
    align-items: center;
  }
  .app-header .box .left-header .logo {
    cursor: pointer;
    width: 264px;
    height: auto;
    margin-right: 20px;
  }
  .app-header .box .left-header .menu-list {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 10px;
  }
  .app-header .box .left-header .menu-list .menuSelect {
    color: #481DA0;
  }
  .app-header .box .left-header .menu-list > span {
    font-size: 16px;
    line-height: 2;
    height: 100%;
    font-family: Helvetica;
    color: #37373C;
    cursor: pointer;
  }
  .app-header .box .left-header .menu-list > a {
    font-size: 16px;
    line-height: 3;
    height: 100%;
    font-family: Helvetica;
    color: #37373C;
    cursor: pointer;
    text-decoration: none;
    margin-left: 45px;
  }
  .app-header .box .left-header .menu-list > span + span {
    margin-left: 45px;
  }
  .app-header .box .left-header .menu-list > span.docs {
    position: relative;
    border-radius: 18px;
    border: 1px solid #FFFFFF;
  }
  .app-header .box .left-header .menu-list > span.docs span {
    position: relative;
    padding: 0 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
  .app-header .box .left-header .menu-list > span.docs div {
    display: none;
    position: absolute;
    right: 0px;
    top: 33px;
    box-sizing: border-box;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 133px;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .app-header .box .left-header .menu-list > span.docs:hover div {
    display: block;
  }
  .app-header .box .left-header .menu-list > span.docs:hover .popup-wrap .up-box {
    display: block;
  }
  .app-header .box .left-header .menu-list > span.docs div a {
    font-size: 16px;
    line-height: 14px;
    text-decoration: none;
    color: #37373C;
    display: block;
    padding: 10px 0;
    font-family: Helvetica;
  }
  .app-header .box .left-header .menu-list > span.docs span::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 10px;
    width: 7px;
    height: 5px;
  }
  .app-header .box .left-header .menu-list > span.docs div a:hover {
    color: #ffffff;
    color: #7D6EF4;
  }
  .app-header .box .left-header .menu-list > span.docs div .popup-con {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    font-size: 13px;
    color: #666;
    line-height: 24px;
    padding: 9px 20px;
    display: block;
    top: 0;
  }
  .app-header .box .left-header .menu-list > span.docs div .popup-con:before {
    width: 10px;
    height: 10px;
    background: #ccc;
    content: '';
    position: absolute;
    top: -5px;
    right: 15px;
    margin-left: -5px;
    transform: rotate(45deg);
    box-shadow: 0 1px 2px 2px #ccc;
  }
  .app-header .box .left-header .menu-list > span.docs div .popup-con:after {
    width: 100%;
    height: 10px;
    background: #DFDFF2;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    content: '';
    border-radius: 6px;
    display: block;
  }
  .app-header .box .right-header {
    display: flex;
    align-items: center;
  }
  .app-header .box .right-header a {
    display: flex;
    align-items: center;
  }
  .app-header .box .right-header img {
    cursor: pointer;
    margin-right: 30px;
  }
  .app-header .box .right-header .medium:hover {
    content: url('../assets/imgs/medium-circle_selected.png');
  }
  .app-header .box .right-header .twitter-logo:hover {
    content: url('../assets/imgs/twitter-sel.png');
  }
  .app-header .box .right-header .in-logo:hover {
    content: url('../assets/imgs/in-sel.png');
  }
  .app-header .box .right-header .tele-logo:hover {
    content: url('../assets/imgs/tele-sel.png');
  }
  .app-header .box .bcaJjD {
    display: none;
  }
}
